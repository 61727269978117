import { ErrorHandler } from '@angular/core';

export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (typeof error === 'string') {
      if (error.includes('Failed to fetch dynamically imported module')) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
        return;
      }
    } else {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        if (confirm('New version available. Load New Version?')) {
          window.location.reload();
        }
      }
    }
  }
}
